




















































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { DashCitasPendientes } from "@/shared/dtos/DashCitasPendientes";
import DashCitasPendientesModule from "@/store/modules/Dashboards/DashCitasPendientes-module";
import { RouterNames } from "@/router/routernames";
import { appointment } from "@/shared/dtos/appointment";
import appointmentModule from "@/store/modules/appointment-module";
import { UtilsPaciente } from "@/utils/utils-paciente";
import { paciente } from "@/shared/dtos/paciente-dto";
import { UtilsEncript } from "@/utils/utils-encript";
import { UtilsNotify } from "@/utils/utils-notify";
@Component({
  components: {
    ModificarCita: () => import("../calendario/ModificarCita.vue")
  }
})
export default class PaciCita extends Vue {
  @Prop() CitaPendiente!: DashCitasPendientes;
  public ModifyCitaShow: boolean = false;
  public EventModify: appointment = new appointment();
  public created() {}
  public get citasPaciente() {
    return DashCitasPendientesModule.DashCitasPendientess;
  }
  public get getimagen() {
    return UtilsPaciente.getrutaImagen(new paciente());
  }
  public Class(Cita: DashCitasPendientes) {
    if (Cita.rojo) {
      return "color:red;";
    }
    return "";
  }
  public get MasOpciones() {
    return [
      { id: 1, texto: "Consultar perfil", class: "fad fa-user miprimary" },
      {
        id: 2,
        texto: "Cambiar la fecha",
        class: "fad fa-calendar-edit miprimary"
      },
      {
        id: 3,
        texto: "Cancelar la consultar",
        class: "fad fa-calendar-times miprimary"
      }
    ];
  }
  public async OptionClick(paciente: DashCitasPendientes, item: any) {
    switch (item.id) {
      case 1:
        this.$router.push({
          name: RouterNames.pacientesedit,
          query: {
            id: UtilsEncript.Encriptar(paciente.numero_paciente.toString())
          }
        });
        break;
      case 2:
        this.EventModify = await appointmentModule.getappointment(
          paciente.id_cita
        );
        this.ModifyCitaShow = true;
        break;
      case 3:
        this.EventModify = await appointmentModule.getappointment(
          paciente.id_cita
        );
        //@ts-ignore
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Confirme la cancelación`,
          acceptText: "Aceptar",
          cancelText: "Cancelar",
          text: "¿Seguro que quiere cambiar la cita de pendiente a cancelada?",
          accept: () => this.acceptAlertModificar(this.EventModify)
        });
        break;
    }
  }
  public async acceptAlertModificar(cita: appointment) {
    cita.ResourceID = 2;
    await appointmentModule.modificarappointment(cita);
    await DashCitasPendientesModule.getDashCitasPendientess();
    UtilsNotify.Notificacion(
      "Cita cancelada",
      "La cita se ha cancelado correctamente",
      "orange"
    );
  }
  public TerminarCita(paciente: DashCitasPendientes) {
    //@ts-ignore
    this.$vs.dialog({
      type: "confirm",
      color: "success",
      title: `Consulta terminada`,
      acceptText: "Aceptar",
      cancelText: "Cancelar",
      text:
        "Este proceso marcará la consulta como terminada. ¿Desea continuar?",
      accept: () => this.acceptTerminarCita(paciente.id_cita)
    });
  }
  public async acceptTerminarCita(id_cita: number) {
    let cita = await appointmentModule.getappointment(id_cita);
    cita.ResourceID = 1;
    await appointmentModule.modificarappointment(cita);
    await DashCitasPendientesModule.getDashCitasPendientess();
    UtilsNotify.NotificacionSuccess(
      "Cita terminada",
      "Enhorabuena, a terminado la consulta con el paciente."
    );
  }

  public ini_consulta(paciente: DashCitasPendientes) {
    this.$router.push({
      name: RouterNames.entrevista,
      query: {
        id_paciente: UtilsEncript.Encriptar(
          paciente.numero_paciente.toString()
        ),
        id_cita: UtilsEncript.Encriptar(paciente.id_cita.toString())
      }
    });
  }
}
